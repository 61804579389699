.previewWrapper div {
  position: relative;
}

.previewWrapper .mainContent {
  display: flex;
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}
.previewWrapper .mainContent > .componentWrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 100%;
  scroll-snap-align: center;
  width: 100%;
}
.previewWrapper .mainContent > .componentWrapper > .visor {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.visor {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.visor[data-direction='column'] {
  flex-direction: column;
}
.visor > div {
  display: flex;
}
.visor[data-direction='column'] > div {
  flex-direction: row;
}
.visor[data-direction='row'] > div {
  flex-direction: column;
}
.visor > div[data-height='fill'] {
  flex-grow: 1;
}
.visor .componentWrapper[data-width='100%'] {
  width: -webkit-fill-available !important;
}
.visor .componentWrapper[data-height='fill'] {
  flex-grow: 1;
}
.visor .componentWrapper[data-height='wrap'],
.visor .componentWrapper[data-height='wrap'] > .visor {
  height: fit-content;
}
.visor iframe {
  border: none;
  display: block;
}
.visor[data-direction='column'] > div {
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
}
.visor[data-direction='column'] > div[data-position='absolute'] > div {
  position: relative !important;
}
.visor[data-direction='row'] > div > .componentWrapper {
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
}
.visor > div[data-position='absolute'] {
  position: absolute;
  top: 0;
  left: 0;
}
.visor > div[data-position='absolute'][data-halign='center'] {
  left: 50%;
  transform: translate(-50%, 0);
}
.visor
  > div[data-position='absolute'][data-halign='center'][data-valign='middle'] {
  transform: translate(-50%, -50%);
}
.visor > div[data-position='absolute'][data-halign='end'] {
  left: unset;
  right: 0;
}
.visor > div[data-position='absolute'][data-valign='middle'] {
  top: 50%;
  transform: translate(0, -50%);
}
.visor > div[data-position='absolute'][data-valign='bottom'] {
  top: unset;
  bottom: 0;
}
.visor > div[data-halign='center'],
.visor > div[data-halign='center'] > .componentWrapper > div {
  justify-content: center;
}
.visor > div[data-halign='end'],
.visor > div[data-halign='end'] > .componentWrapper > div {
  justify-content: flex-end;
}
.visor > div[data-valign='middle'],
.visor > div[data-valign='middle'] > .componentWrapper > div {
  align-items: center;
}
.visor > div[data-valign='bottom'],
.visor > div[data-valign='bottom'] > .componentWrapper > div {
  align-items: flex-end;
}
.visor > div > .componentWrapper {
  overflow: hidden;
  background-position: center;
  background-size: cover;
}
.visor > div > .componentWrapper[data-type='group'] {
  display: flex;
}
.visor > div > .componentWrapper[data-type='group'][data-direction='column'] {
  flex-direction: column;
}
.visor
  > div
  > .componentWrapper[data-type='group'][data-height='fill']
  > .visor,
.visor > div > .componentWrapper[data-type='group'][style*='height:'] > .visor {
  height: 100%;
}
.visor > div > .componentWrapper[data-type='group'] > .visor {
  width: 100%;
}
.visor
  > div
  > .componentWrapper[data-type='group']
  > .visor[data-scroll='true'][data-direction='column'] {
  overflow-y: auto;
}
.visor
  > div
  > .componentWrapper[data-type='group']
  > .visor[data-scroll='true'][data-direction='row'] {
  overflow-x: auto;
}
.visor > div > .componentWrapper[data-type='image'] > div {
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.visor
  > div
  > .componentWrapper[data-type='image']
  > div[data-scale][data-scale='crop'] {
  background-size: cover;
}
.visor
  > div
  > .componentWrapper[data-type='image']
  > div[data-scale][data-scale='crop']
  img {
  display: none;
}
.visor
  > div
  > .componentWrapper[data-type='image'][data-height='100%'][data-width='100%'] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.visor
  > div
  > .componentWrapper[data-type='image'][data-height='100%'][data-width='100%']
  img {
  height: 100%;
  object-fit: fill;
  position: absolute;
  width: 100%;
}
.visor > div > .componentWrapper[data-type='image'] img {
  object-fit: cover;
  width: 100%;
}
.visor > div > .componentWrapper[data-type='inputtext'] {
  padding: 1px;
}
.visor > div > .componentWrapper[data-type='inputtext'] > input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--secondaryText);
  outline: none;
  width: 100%;
}
.visor > div > .componentWrapper[data-type='inputtext'] > input:focus {
  border-color: var(--primary);
}
.visor > div > .componentWrapper[data-type='text'] > div {
  display: flex;
  height: 100%;
  width: 100%;
  word-break: break-word;
}
.visor > div > .componentWrapper[data-type='text'] [data-align='flex-start'] {
  justify-content: flex-start !important;
  text-align: left;
}
.visor > div > .componentWrapper[data-type='text'] [data-align='center'] {
  justify-content: center !important;
  text-align: center;
}
.visor > div > .componentWrapper[data-type='text'] [data-align='flex-end'] {
  justify-content: flex-end !important;
  text-align: right;
}
.visor > div > .componentWrapper[data-type='text'] [data-valign='top'] {
  align-items: flex-start !important;
}
.visor > div > .componentWrapper[data-type='text'] [data-valign='middle'] {
  align-items: center !important;
}
.visor > div > .componentWrapper[data-type='text'] [data-valign='bottom'] {
  align-items: flex-end !important;
}
.visor > div > .componentWrapper[data-type='map'] iframe,
.visor > div > .componentWrapper[data-type='pdfviewer'] iframe,
.visor > div > .componentWrapper[data-type='web'] iframe,
.visor > div > .componentWrapper[data-type='youtube'] > div > div,
.visor > div > .componentWrapper[data-type='youtube'] iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.visor > div > .componentWrapper[data-type='video'] {
  display: flex;
  flex-direction: column;
}
.visor > div > .componentWrapper[data-type='video'] > div,
.visor > div > .componentWrapper[data-type='video'] video {
  height: 100%;
  width: 100%;
}
.visor > div > .componentWrapper[data-type='video'][data-height='wrap'] video {
  height: auto;
}
.visor > div > .componentWrapper[data-type='video'] > div {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
.visor > div > .componentWrapper[data-type='youtube'] > div:before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.componentWrapper[data-clickable='true'] {
  cursor: pointer !important;
}
.componentWrapper[data-clickable='true']:hover {
  opacity: 0.8 !important;
}
