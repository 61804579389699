/* add css module styles here (optional) */

.root {
  --background: rgba(0, 0, 0, 0.4);
  --circle: var(--accent);
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: 99998;
}

.flow {
  position: fixed;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99998;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  visibility: visible;
}
.flow > .focus {
  position: fixed;
  width: 0;
  height: 0;
  border: 2px solid var(--circle);
  box-shadow: 0 0 0 3000px var(--background);
  pointer-events: none;
  transition: all 0.2s ease;
  will-change: transform, width, height;
  z-index: 99999;
}
.flow > .focus[data-has-target='false'] {
  border: none;
}

.flow[data-blocking='true'] {
  pointer-events: initial;
}
.flow[data-visible='false'] {
  visibility: hidden;
}
.flow > .focus[data-shape='circle'] {
  border-radius: 100%;
}
.flow > .focus[data-shape='rounded'] {
  border-radius: 1rem;
}
.flow > .focus[data-shape='square'] {
  border-radius: 0;
}
