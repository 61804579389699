.button {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  color: #ffffff;
  cursor: pointer;
}

.button:hover {
  background-color: var(--primaryDark);
  border-color: var(--primaryDark);
}
