.root {
}

.wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  outline: 0;
  transform-origin: center;
  z-index: 100000;
}

.wrap[data-appear] {
  transform: none;
  opacity: 0;
  animation-duration: 0.35s;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  user-select: none;
  animation-name: modalZoomIn;
  height: 100vh;
  perspective: none;
  width: 100vw;
  will-change: transform, opacity;
}

.modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: auto;
  max-width: 520px;
  max-height: 90vh;
  transform-origin: center;
  transition: transform 0.2s ease;
  transform: translate(calc(50vw - 50%), calc(50vh - 50%)) scale(0.2);
  will-change: transform;
}
.modal[data-visible='false'] {
  opacity: 0;
  transform: translate(calc(50vw - 50%), calc(50vh - 50%)) scale(0.2) !important;
}
.modal:before {
  position: absolute;
}
.modal[data-arrow-x='left']:before,
.modal[data-arrow-x='right']:before {
  top: 50%;
}
.modal[data-arrow-x='left']:before {
  content: '⬅️';
  left: -5px;
  transform: translate(-100%, -50%);
}
.modal[data-arrow-x='right']:before {
  content: '➡️';
  right: -5px;
  transform: translate(100%, -50%);
}
.modal[data-arrow-y='top']:before,
.modal[data-arrow-y='bottom']:before {
  left: 50%;
}
.modal[data-arrow-y='top']:before {
  content: '⬆️';
  top: -5px;
  transform: translate(-50%, -100%);
}
.modal[data-arrow-y='bottom']:before {
  content: '⬇️';
  bottom: -5px;
  transform: translate(-50%, 100%);
}
.modal[data-arrow-x='left'][data-arrow-y='top'] {
}
.modal[data-arrow-x='left'][data-arrow-y='bottom'] {
}
.modal[data-arrow-x='right'][data-arrow-y='top'] {
}
.modal[data-arrow-x='right'][data-arrow-y='bottom'] {
}

.modalContent {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  transition: all 0.2s ease;
  will-change: width, height;
  width: 100%;
  text-rendering: optimizeLegibility;
}
.modalContent[data-visible='false'] > .header,
.modalContent[data-visible='false'] > .body,
.modalContent[data-visible='false'] > .footer {
  opacity: 0 !important;
}
.modalContent[data-appear] > .header,
.modalContent[data-appear] > .body,
.modalContent[data-appear] > .footer {
  transform: none;
  opacity: 0;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  user-select: none;
  animation-name: modalContentZoomIn;
  perspective: none;
  will-change: transform, opacity;
}

.close {
  box-shadow: none;
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 0;
  top: 0;
  right: 0;
  font-size: 28px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 54px;
  border: none;
  background: transparent;
  color: rgba(0, 0, 0, 0.5);
  outline: none;
  width: 55px;
  height: 55px;
}
.close:hover {
  border: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.85);
}
.close:before {
  content: '×';
  padding: 0 16px;
}

.header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px 2px 0 0;
  min-height: 55px;
}

.title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.body {
  flex-grow: 1;
  overflow: auto;
  padding: 24px;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0 24px;
  margin: 0;
  list-style: none;
}
.actions > button {
  margin: 12px 0;
}
.actions > button:not(:last-child) {
  margin-right: 12px;
}

.header:empty,
.footer:empty,
.actions:empty {
  display: none;
}

.credit {
  padding: 3px;
}
.credit a {
  align-items: center;
  background-color: #ffffff;
  border: none;
  border-radius: 3px;
  color: #666666;
  display: inline-flex;
  font-size: 10px;
  opacity: 0.8;
  padding: 8px;
  text-decoration: none;
  transform: scale(1);
  transition: all 0.2s ease;
  will-change: opacity, transform;
}
.credit a:before {
  content: '';
  width: 23px;
  height: 23px;
  background-image: url(http://codoozer.com/favicon/favicon-32x32.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.credit a:after {
  border-radius: 1px;
  content: 'Powered by Codoozer';
  margin-left: 14px;
  line-height: 14px;
}
.credit a:hover {
  color: #333333 !important;
  opacity: 1;
  transform: scale(1.035);
}

@keyframes modalZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes modalContentZoomIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
